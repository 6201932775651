.icon-card {
  display: grid;
  grid-template-columns: 3rem auto;
  grid-template-rows: auto 1fr;
  padding: var(--icon-card-padding, 1rem);
  gap: var(--icon-card-gap, 0.75rem);
}
.icon-card > *:first-child {
  grid-row: 1 / 3;
}

.thin-button {
  --padding-cross: 0.75em;
}
