@mixin small {
  @content;
}

@mixin medium {
  @media only screen and (min-width: 600px) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width: 900px) {
    @content;
  }
}

@mixin xl {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}

@mixin modifiers($mixins...) {
  @content (small);

  @include medium {
    @content (medium);
  }

  @include large {
    @content (large);
  }

  @include xl {
    @content (xl);
  }
}

@mixin sizeSelector($selector, $size) {
  @if $size == "small" {
    .#{$selector},
    .#{$selector}\:#{$size} {
      @content;
    }
  } @else {
    .#{$selector}\:#{$size} {
      @content;
    }
  }
}

.styles2 {
  @media only screen and (max-width: 599px) {
    :is(.ss-medium, .ss-large, .ss-xl):not(.ss-small) {
      display: none !important;
    }
  }

  @media only screen and (max-width: 899px) {
    :is(.ss-large, .ss-xl):not(.ss-small, .ss-medium) {
      display: none !important;
    }
  }

  @media only screen and (max-width: 1199px) {
    :is(.ss-xl):not(.ss-small, .ss-medium, .ss-large) {
      display: none !important;
    }
  }

  @media only screen and (min-width: 899px) {
    :is(.ss-small):not(.ss-medium, .ss-large, .ss-xl) {
      display: none !important;
    }
  }

  .ff-clash {
    font-family: var(--font-clash);
  }

  .ff-clash-display {
    font-family: var(--font-clash-display);
  }

  .ff-switzer {
    font-family: var(--font-sfpro);
  }

  .fw-normal {
    font-weight: 400;
  }

  .fw-semibold {
    font-weight: 500;
  }

  .fw-bold {
    font-weight: 600;
  }

  .fs-13 {
    font-size: 13px;
  }

  .fs-14 {
    font-size: 14px;
  }

  .fs-16 {
    font-size: 16px;
  }

  .fs-18 {
    font-size: 18px;
  }

  .fs-19 {
    font-size: 18px;
  }

  .fs-20 {
    font-size: 20px;
  }

  .fs-24 {
    font-size: 24px;
  }

  .fs-30 {
    font-size: 30px;
  }

  .fs-32 {
    font-size: 32px;
  }

  .fs-36 {
    font-size: 36px;
  }

  .fs-40 {
    font-size: 40px;
  }

  .fs-48 {
    font-size: 48px;
  }

  .width-min {
    width: min-content;
  }

  .width-fit {
    width: fit-content;
  }

  .width-max {
    width: max-content;
  }

  .width-100 {
    width: 100%;
  }

  .width-text {
    width: min(80ch, 100%);
  }

  .width-1fr {
    width: 1fr;
  }

  .width-grow {
    flex-grow: 1;
  }

  @include modifiers using ($size) {
    @include sizeSelector(row, $size) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    @include sizeSelector(row-start, $size) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    @include sizeSelector(row-center, $size) {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    @include sizeSelector(row-between, $size) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    @include sizeSelector(row-around, $size) {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    @include sizeSelector(row-end, $size) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    @include sizeSelector(inline-row, $size) {
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    @include sizeSelector(inline-row-start, $size) {
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    @include sizeSelector(inline-row-center, $size) {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
    }

    @include sizeSelector(inline-row-between, $size) {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
    }

    @include sizeSelector(inline-row-around, $size) {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-around;
    }

    @include sizeSelector(inline-row-end, $size) {
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    @include sizeSelector(col, $size) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    @include sizeSelector(col-start, $size) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    @include sizeSelector(col-center, $size) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @include sizeSelector(col-between, $size) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @include sizeSelector(col-around, $size) {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    @include sizeSelector(col-end, $size) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    @include sizeSelector(align-start, $size) {
      align-items: flex-start;
    }

    @include sizeSelector(align-center, $size) {
      align-items: center;
    }

    @include sizeSelector(align-end, $size) {
      align-items: flex-end;
    }

    @include sizeSelector(align-stretch, $size) {
      align-items: stretch;
    }

    @include sizeSelector(align-self-start, $size) {
      align-self: flex-start;
    }

    @include sizeSelector(align-self-center, $size) {
      align-self: center;
    }

    @include sizeSelector(align-self-end, $size) {
      align-self: flex-end;
    }

    @include sizeSelector(align-self-stretch, $size) {
      align-self: stretch;
    }

    @include sizeSelector(flex-wrap, $size) {
      flex-wrap: wrap;
    }

    @include sizeSelector(gap, $size) {
      gap: 1rem;
    }

    @include sizeSelector(gap-large, $size) {
      gap: 2rem;

      @media (max-width: 700px) {
        gap: 1rem;
      }
    }

    @include sizeSelector(gap-small, $size) {
      gap: 0.5rem;
    }

    @include sizeSelector(padding, $size) {
      padding: 1rem;
    }

    @include sizeSelector(padding-large, $size) {
      padding: 2rem;
    }

    @include sizeSelector(padding-small, $size) {
      padding: 0.5rem;
    }

    @include sizeSelector(padding-none, $size) {
      padding: 0;
    }

    @include sizeSelector(padding-inline, $size) {
      padding-inline: 1rem;
    }

    @include sizeSelector(padding-inline-large, $size) {
      padding-inline: 2rem;
    }

    @include sizeSelector(padding-inline-small, $size) {
      padding-inline: 0.5rem;
    }

    @include sizeSelector(padding-block, $size) {
      padding-block: 1rem;
    }

    @include sizeSelector(padding-block-large, $size) {
      padding-block: 2rem;
    }

    @include sizeSelector(padding-block-small, $size) {
      padding-block: 0.5rem;
    }

    @include sizeSelector(margin-small, $size) {
      margin: 0.5rem;
    }

    @include sizeSelector(margin, $size) {
      margin: 1rem;
    }

    @include sizeSelector(margin-large, $size) {
      margin: 2rem;
    }

    @include sizeSelector(margin-xl, $size) {
      margin: 4rem;
    }

    @include sizeSelector(margin-auto, $size) {
      margin: auto;
    }

    @include sizeSelector(margin-inline, $size) {
      margin-inline: 1rem;
    }

    @include sizeSelector(margin-inline-large, $size) {
      margin-inline: 2rem;
    }

    @include sizeSelector(margin-inline-small, $size) {
      margin-inline: 0.5rem;
    }

    @include sizeSelector(margin-inline-auto, $size) {
      margin-inline: auto;
    }

    @include sizeSelector(margin-block, $size) {
      margin-block: 1rem;
    }

    @include sizeSelector(margin-block-large, $size) {
      margin-block: 2rem;
    }

    @include sizeSelector(margin-block-small, $size) {
      margin-block: 0.5rem;
    }

    @include sizeSelector(margin-block-auto, $size) {
      margin-block: auto;
    }

    @include sizeSelector(auto-grid, $size) {
      display: grid;
      grid-template-columns: repeat(
        var(--grid-placement, auto-fit, minmax(min(var(--grid-min-item-size, 200px), 100%), 1fr))
      );
    }

    @include sizeSelector(split-grid, $size) {
      display: grid;
      grid-auto-columns: 1fr;
      justify-items: center;

      > * {
        grid-row: 1;
      }

      > *:first-child {
        justify-self: start;
      }

      > *:last-child {
        justify-self: end;
      }
    }
  }

  .round {
    border-radius: 6px;
  }

  .border {
    border: 1px solid var(--dark-8);
  }

  .border-dashed {
    border: 1px dashed var(--dark-8);
  }

  .form-flow-small > * + label {
    margin-top: 0.5em;
    display: inline-block;
  }
  .form-flow-small > label + * {
    margin-top: 0.25em;
  }
  .flow-xl > * + * {
    margin-top: 4em;
  }

  .flow-xl {
    padding-top: 3rem;
  }

  .flow-large > * + * {
    margin-top: 2em;
  }

  .flow > * + * {
    margin-top: 1em;
  }

  .flow-small > * + * {
    margin-top: 0.5em;
  }

  .flow-row-xl > * + * {
    margin-left: 4em;
  }

  .flow-row-large > * + * {
    margin-left: 2em;
  }

  .flow-row > * + * {
    margin-left: 1em;
  }

  .flow-row-small > * + * {
    margin-left: 0.5em;
  }

  .join-inputs {
    > * + * {
      margin-left: -1px;
    }

    > :is(:hover, :focus, :focus-visible, :focus-within, :active, :has(> :active)) {
      position: relative;
    }

    > :not(:first-child) {
      border-start-start-radius: 0;
      border-end-start-radius: 0;
    }

    > :not(:last-child) {
      border-start-end-radius: 0;
      border-end-end-radius: 0;
    }
  }

  .nowrap {
    white-space: nowrap;
  }

  .center-text {
    text-align: center;
  }
}
