@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-lightGray text-foreground;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }
}

@font-face {
  font-family: 'SF Pro';
  src: url('/fonts/SFProDisplay-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('/fonts/SFProDisplay-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('/fonts/SFProDisplay-Heavy.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('/fonts/SFProDisplay-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('/fonts/SFProDisplay-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

html,
body {
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-family: var(--font-sfpro);
}

h1,
h2,
h3,
h4,
h5,
h6,
h7,
p,
span {
  font-weight: normal;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  font-family: var(--font-sfpro);
}

b {
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
}

* {
  box-sizing: border-box;
}

:root {
  --font-clash: ClashGrotesk-Variable, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --font-clash-display: ClashDisplay-Variable, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --font-sfpro: SFProDisplay-Variable, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --font-sfpro: 'SF Pro', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: var(--dark-100, black);
}

.font-clash {
  font-family: var(--font-clash);
}
.font-sfpro {
  font-family: var(--font-sfpro);
}
.font-clash-display {
  font-family: var(--font-clash-display);
}

[data-nextjs-scroll-focus-boundary] {
  display: contents;
}

@layer base {
  * {
    @apply tracking-default;
  }
}
