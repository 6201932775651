/**
 * .button creates a basic button with interaction styles
 *
 * @attr data-variant default - 
 * @attr data-variant="primary" - blue button
 * @attr data-variant="secondary" - white button
 * @attr data-variant="safe" - green button
 * @attr data-variant="danger" - red button
 * @attr data-variant="black" - black button
 * 
 * @var --btn-fg - button text color
 * @var --btn-bg - button background color
 * @var --btn-border - button border color
 *
 * @var --btn-fg--disabled - disabled button text color
 * @var --btn-bg--disabled - disabled button background color
 * @var --btn-border--disabled - disabled button border color
 *
 * @var --btn-fg--focus - focused button text color
 * @var --btn-bg--focus - focused button background color
 * @var --btn-border--focus - focused button border color
 * @var --btn-shadow--focus - focused button shadow color
 *
 * @var --btn-fg--active - active button text color
 * @var --btn-bg--active - active button background color
 * @var --btn-border--active - active button border color
 *
 * @var --btn-fg--hover - hover button text color
 * @var --btn-bg--hover - hover button background color
 * @var --btn-border--hover - hover button border color
 */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 30px;
  line-height: 1.2em;

  &:not([class*="text-"]) {
    font-size: 16px;
  }

  padding-inline: 1em;
  padding-block: 0.75em;

  &:where(.square) {
    padding-inline: 0.75em;
    padding-block: 0.75em;
    aspect-ratio: 1 / 1;
  }

  color: var(--btn-fg, var(--white));
  background: var(--btn-bg, var(--true-blue));
  border-width: 1px;
  border-style: solid;
  border-color: var(--btn-border, var(--light-blue-64));
  outline: none;

  /*
    Precedence is as follows:
    default < focus < hover < active < disabled
    The only exception to this that focus border color takes precedence
    over hover border color.
  */

  &:where(:focus, :focus-within, .focus) {
    color: var(--btn-fg--focus, var(--white));
    background-color: var(--btn-bg--focus);
  }

  &:where(:focus-visible) {
    box-shadow: 0 0 0 0.15em var(--btn-shadow--focus);
  }

  &:where(:hover, .hover) {
    color: var(--btn-fg--hover, var(--white));
    background-color: var(--btn-bg--hover);
    border-color: var(--btn-border--hover);
  }

  &:where(:focus, :focus-within, .focus) {
    border-color: var(--btn-border--focus);
  }

  &:where(:active, .active) {
    color: var(--btn-fg--active, var(--white));
    background-color: var(--btn-bg--active);
    border-color: var(--btn-border--active);
    box-shadow: none;
  }

  &:where(:disabled, [aria-disabled="true"]) {
    cursor: not-allowed;
    color: var(--btn-fg--disabled, var(--white));
    background-color: var(--btn-bg--disabled);
    border-color: var(--btn-border--disabled);
  }

  &:where([data-variant="primary"], :not([data-variant])) {
    --btn-fg: var(--white);
    --btn-bg: var(--true-blue);
    --btn-border: transparent;

    --btn-fg--disabled: var(--dark-2);
    --btn-bg--disabled: var(--true-blue--disabled);
    --btn-border--disabled: transparent;

    --btn-fg--focus: var(--white);
    --btn-bg--focus: var(--true-blue);
    --btn-border--focus: transparent;
    --btn-shadow--focus: var(--light-blue-32);

    --btn-fg--active: var(--white);
    --btn-bg--active: var(--true-blue--active);
    --btn-border--active: var(--true-blue);

    --btn-fg--hover: var(--white);
    --btn-bg--hover: var(--true-blue--hover);
    --btn-border--hover: transparent;
  }

  &:where([data-variant="secondary"]) {
    --btn-fg: var(--min-grey);
    --btn-bg: var(--white);
    --btn-border: var(--dark-8);

    --btn-fg--disabled: var(--dark-32);
    --btn-bg--disabled: var(--dark-8);
    --btn-border--disabled: transparent;

    --btn-fg--focus: var(--min-grey);
    --btn-bg--focus: var(--white);
    --btn-border--focus: var(--dark-32);
    --btn-shadow--focus: var(--light-blue-32);

    --btn-fg--active: var(--min-grey);
    --btn-bg--active: var(--white);
    --btn-border--active: var(--dark-32);

    --btn-fg--hover: var(--min-grey);
    --btn-bg--hover: var(--white);
    --btn-border--hover: var(--dark-16);
  }

  &:where([data-variant="danger"]) {
    --btn-fg: var(--white);
    --btn-bg: var(--red);
    --btn-border: transparent;

    --btn-fg--disabled: var(--dark-2);
    --btn-bg--disabled: var(--red--disabled);
    --btn-border--disabled: transparent;

    --btn-fg--focus: var(--white);
    --btn-bg--focus: var(--red);
    --btn-border--focus: transparent;
    --btn-shadow--focus: var(--light-blue-32);

    --btn-fg--active: var(--white);
    --btn-bg--active: var(--red--active);
    --btn-border--active: var(--red);

    --btn-fg--hover: var(--white);
    --btn-bg--hover: var(--red--hover);
    --btn-border--hover: transparent;
  }

  &:where([data-variant="safe"]) {
    --btn-fg: var(--white);
    --btn-bg: var(--green);
    --btn-border: transparent;

    --btn-fg--disabled: var(--dark-2);
    --btn-bg--disabled: var(--green--disabled);
    --btn-border--disabled: transparent;

    --btn-fg--focus: var(--white);
    --btn-bg--focus: var(--green);
    --btn-border--focus: transparent;
    --btn-shadow--focus: var(--light-blue-32);

    --btn-fg--active: var(--white);
    --btn-bg--active: var(--green--active);
    --btn-border--active: var(--green);

    --btn-fg--hover: var(--white);
    --btn-bg--hover: var(--green--hover);
    --btn-border--hover: transparent;
  }

  &:where([data-variant="black"]) {
    --btn-fg: var(--white);
    --btn-bg: var(--dark-100);
    --btn-border: transparent;

    --btn-fg--disabled: var(--dark-16);
    --btn-bg--disabled: var(--dark-100--disabled);
    --btn-border--disabled: transparent;

    --btn-fg--focus: var(--white);
    --btn-bg--focus: var(--dark-100);
    --btn-border--focus: transparent;
    --btn-shadow--focus: var(--light-blue-32);

    --btn-fg--active: var(--white);
    --btn-bg--active: var(--dark-100--active);
    --btn-border--active: var(--dark-100);

    --btn-fg--hover: var(--white);
    --btn-bg--hover: var(--dark-100--hover);
    --btn-border--hover: transparent;
  }

  &:where([data-variant="transparent"]) {
    --btn-fg: var(--min-grey);
    --btn-bg: transparent;
    --btn-border: transparent;

    --btn-fg--disabled: var(--dark-32);
    --btn-bg--disabled: transparent;
    --btn-border--disabled: transparent;

    --btn-fg--focus: var(--dark-64);
    --btn-bg--focus: transparent;
    --btn-border--focus: transparent;
    --btn-shadow--focus: var(--light-blue-32);

    --btn-fg--active: var(--dark-72);
    --btn-bg--active: transparent;
    --btn-border--active: transparent;

    --btn-fg--hover: var(--dark-64);
    --btn-bg--hover: transparent;
    --btn-border--hover: transparent;
  }

  // Gradient is a pretty half-baked variant, but it's only used in one place so whatever
  &:where([data-variant="gradient"]) {
    background-clip: padding-box;

    --btn-fg: var(--white);
    --btn-bg: var(--blue-gradient);
    --btn-border: transparent;

    --btn-fg--disabled: var(--white);
    --btn-bg--disabled: var(--blue-gradient);
    --btn-border--disabled: transparent;

    --btn-fg--focus: var(--white);
    --btn-bg--focus: var(--blue-gradient);
    --btn-border--focus: transparent;
    --btn-shadow--focus: var(--light-blue-32);

    --btn-fg--active: var(--white);
    --btn-bg--active: var(--blue-gradient);
    --btn-border--active: transparent;

    --btn-fg--hover: var(--white);
    --btn-bg--hover: var(--blue-gradient);
    --btn-border--hover: transparent;
  }

  &:where([data-variant="outline"]) {
    --btn-fg: var(--dark-100);
    --btn-bg: transparent;
    --btn-border: var(--true-blue);

    --btn-fg--disabled: var(--dark-32);
    --btn-bg--disabled: transparent;
    --btn-border--disabled: var(--dark-32);

    --btn-fg--focus: var(--dark-72);
    --btn-bg--focus: transparent;
    --btn-border--focus: var(--true-blue);
    --btn-shadow--focus: var(--light-blue-32);

    --btn-fg--active: var(--dark-72);
    --btn-bg--active: transparent;
    --btn-border--active: var(--true-blue);

    --btn-fg--hover: var(--dark-72);
    --btn-bg--hover: transparent;
    --btn-border--hover: var(--true-blue);
  }

  &:where([data-variant="integration"]) {
    --btn-fg: var(--true-blue);
    --btn-bg: var(--light-blue-8);
    --btn-border: var(--true-blue-64);

    --btn-fg--disabled: var(--dark-32);
    --btn-bg--disabled: var(--dark-8);
    --btn-border--disabled: transparent;

    --btn-fg--focus: var(--min-grey);
    --btn-bg--focus: var(--white);
    --btn-border--focus: var(--dark-32);
    --btn-shadow--focus: var(--light-blue-32);

    --btn-fg--active: var(--min-grey);
    --btn-bg--active: var(--white);
    --btn-border--active: var(--dark-32);

    --btn-fg--hover: var(--min-grey);
    --btn-bg--hover: var(--white);
    --btn-border--hover: var(--dark-16);
  }
}
