.select {
  font-size: 16px;
  border-radius: 5px;
  display: inline-block;
  position: relative;

  border-width: 1px;
  border-style: solid;
  border-color: var(--select-border);

  width: auto;

  &::after {
    display: block;
    content: " ";
    pointer-events: none;
    width: 0.5em;
    height: 0.5em;
    position: absolute;
    right: 0.75em;
    top: 50%;
    margin-top: -0.47em;
    transform-origin: center;
    rotate: 45deg;
    border-right-style: solid;
    border-right-width: 2px;
    border-right-color: var(--select-fg);
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: var(--select-fg);
    border-radius: 1px;
  }

  & :where(select) {
    appearance: none;
    border: none;
    border-radius: 5px;

    line-height: 1.2em;

    position: relative;

    padding-inline: 1em;
    padding-right: 2.2em;
    padding-block: 0.75em;

    font-size: inherit;

    color: var(--select-fg);
    background-color: var(--select-bg);
    outline: none;

    width: 100%;
    height: 100%;

    text-overflow: ellipsis;
  }

  &:where(.focus, :focus, :focus-within) {
    color: var(--select-fg--focus);
    background-color: var(--select-bg--focus);
  }

  &:where(:has(> :focus-visible)) {
    box-shadow: 0 0 0 0.15em var(--select-shadow--focus);
  }

  &:where(:hover) {
    color: var(--select-fg--hover);
    background-color: var(--select-bg--hover);
    border-color: var(--select-border--color);
  }

  &:where(:focus, :focus-within) {
    border-color: var(--select-border--focus);
  }

  &:has(:where(:active, :hover)) {
    color: var(--select-fg--active);
    background-color: var(--select-bg--active);
    border-color: var(--select-border--active);
  }

  &:where(:has(> :disabled, > [aria-disabled="true"])) {
    color: var(--select-fg--disabled);
    background-color: var(--select-bg--disabled);
    border-color: var(--select-border--disabled);
    & select {
      cursor: not-allowed;
    }
  }

  &:has(:where(:invalid)) {
    border-color: var(--select-border--invalid);
  }

  &:has(:where([data-show-valid]:valid:required)) {
    border-color: var(--select-border--valid);
  }

  &:where(:not([data-variant])) {
    --select-fg: var(--min-grey);
    --select-bg: var(--white);
    --select-border: var(--dark-8);

    --select-fg--disabled: var(--dark-32);
    --select-bg--disabled: var(--dark-8);
    --select-border--disabled: transparent;

    --select-fg--focus: var(--min-grey);
    --select-bg--focus: var(--white);
    --select-border--focus: var(--dark-32);
    --select-shadow--focus: var(--light-blue-32);

    --select-fg--active: var(--min-grey);
    --select-bg--active: var(--white);
    --select-border--active: var(--dark-32);

    --select-fg--hover: var(--min-grey);
    --select-bg--hover: var(--white);
    --select-border--hover: var(--dark-16);

    --select-border--invalid: var(--red);
    --select-border--valid: var(--green);
  }
}
