.hr {
  border: none;
  height: 8px;
  --blue-gradient-angle: 270deg !important;
  background: linear-gradient(
    var(--blue-gradient-angle),
    var(--blue-gradient-start) 0%,
    var(--blue-gradient-stop) 100%
  );
  opacity: 0.75;
  margin-block: 2rem;
}
