.breadcrumbs ol {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumbs li:not(:last-child)::after {
  margin-inline: 0.5em;
  display: inline-block;
  transform: rotate(20deg);
  border-right: 0.1em solid currentcolor;
  height: 0.8em;
  content: "";
}
