.round {
  border-radius: var(--border-radius, 6px);
}

.border {
  border: solid var(--border-size, 1px) var(--border-color, var(--dark-8));
}

.border-dashed {
  border: dashed var(--border-size, 1px) var(--border-color, var(--dark-8));
}

.borderless {
  border: none;
}

.shadow {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
}

.basis {
  flex-basis: 50%;
}

.basis-0 {
  flex-basis: 0;
}

.grow {
  flex-grow: 1;
}

.padding-inline {
  padding-left: var(--padding-inline, 1em);
  padding-right: var(--padding-inline, 1em);
}

.padding-cross {
  padding-top: var(--padding-cross, 1em);
  padding-bottom: var(--padding-cross, 1em);
}

.padding-half {
  padding: 0.5em;
}

.padding {
  padding-left: var(--padding-inline, 1rem);
  padding-right: var(--padding-inline, 1rem);
  padding-top: var(--padding-cross, 1rem);
  padding-bottom: var(--padding-cross, 1rem);
}

.padding-2 {
  padding: 2rem;
}

.padding-3 {
  padding: 3rem;
}

.padding-bottom-1 {
  padding-bottom: 1rem;
}

.padding-bottom-2 {
  padding-bottom: 2rem;
}

.padding-bottom-3 {
  padding-bottom: 3rem;
}

.padding-bottom-4 {
  padding-bottom: 4rem;
}

.padding-top-1 {
  padding-top: 1rem;
}

.padding-top-2 {
  padding-top: 2rem;
}

.padding-top-3 {
  padding-top: 3rem;
}

.padding-top-4 {
  padding-top: 4rem;
}

.margin-inline {
  margin-left: var(--margin-inline, 1em);
  margin-right: var(--margin-inline, 1em);
}

.margin-cross {
  margin-top: var(--margin-cross, 2em);
  margin-bottom: var(--margin-cross, 2em);
}

.margin-top {
  margin-top: var(--margin-cross, 2em);
}

.margin-top-3px {
  margin-top: 3px;
}

.margin-top-half {
  margin-top: 0.5em;
}

.margin-top-1 {
  margin-top: 1rem;
}
.margin-top-2 {
  margin-top: 2rem;
}

.margin-top-3 {
  margin-top: 3rem;
}
.margin-top-4 {
  margin-top: 4rem;
}
.margin-top-5 {
  margin-top: 5rem;
}

.margin-top-8 {
  margin-top: 8rem;
}

.margin-top-14 {
  margin-top: 14rem;
}

.margin-bottom {
  margin-bottom: var(--margin-cross, 2em);
}

.margin-bottom-half {
  margin-bottom: 0.5em;
}

.margin-bottom-quarter {
  margin-bottom: 0.25em;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.margin-bottom-3 {
  margin-bottom: 3rem;
}

.margin-bottom-4 {
  margin-bottom: 4rem;
}

.margin-bottom-8 {
  margin-bottom: 8rem;
}

.margin-left-half {
  margin-left: 0.5em;
}

.margin-left-1 {
  margin-left: 1rem;
}

.margin-left-2 {
  margin-left: 2rem;
}

.margin-left-3 {
  margin-left: 3rem;
}

.margin-left-4 {
  margin-left: 4rem;
}

.margin-left-5 {
  margin-left: 5rem;
}

.margin-left-6 {
  margin-left: 6rem;
}

.margin-right-half {
  margin-right: 0.5em;
}

.margin-right-1 {
  margin-right: 1rem;
}

.margin-right-2 {
  margin-right: 2rem;
}

.margin-right-3 {
  margin-right: 3rem;
}

.margin-right-4 {
  margin-right: 4rem;
}
.text-10 {
  font-size: 10px;
}
.text-13 {
  font-size: 13px;
}
.text-14 {
  font-size: 14px;
}
.text-16 {
  font-size: 16px;
}
.text-18 {
  font-size: 18px;
}
.text-20 {
  font-size: 20px;
}
.text-24 {
  font-size: 24px;
}
.text-26 {
  font-size: 26px;
}
.text-28 {
  font-size: 28px;
}
.text-30 {
  font-size: 30px;
}
.text-32 {
  font-size: 32px;
}
.text-36 {
  font-size: 36px;
}
.text-40 {
  font-size: 40px;
}
.text-48 {
  font-size: 48px;
}
.text-60 {
  font-size: 60px;
}
.text-80 {
  font-size: 66px;
}

.text-align-left {
  text-align: left;
}

.relative {
  position: relative;
}

.width-100 {
  width: 100%;
}

.width-90 {
  width: 90%;
}

.width-80 {
  width: 80%;
}

.width-75 {
  width: 75%;
}

.width-60 {
  width: 60%;
}

.width-50 {
  width: 50%;
}

.width-40 {
  width: 40%;
}

.width-20 {
  width: 20%;
}

.width-15 {
  width: 15%;
}

.width-10 {
  width: 10%;
}

.width-fit {
  width: fit-content;
}

.horizontal-center {
  margin-left: auto;
  margin-right: auto;
}

.vertical-center {
  margin-top: auto;
  margin-bottom: auto;
}

.center {
  margin: auto;
}

.center-text {
  text-align: center;
}

.center-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notbold {
  font-weight: normal;
}
.semibold {
  font-weight: 500;
}
.bold {
  font-weight: 600;
}

.square {
  aspect-ratio: 1 / 1;
}

.inline {
  display: inline-block;
}

.gap-0 {
  gap: 0;
}
.gap-half {
  gap: 0.5em;
}
.gap-1 {
  gap: 1em;
}
.gap-1andhalf {
  gap: 1.5em;
}
.gap-2 {
  gap: 2em;
}
.gap-3 {
  gap: 3em;
}
.gap-4 {
  gap: 4em;
}
.gap-5 {
  gap: 5em;
}

.align-center {
  display: flex;
  align-items: center;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.underline {
  text-decoration: underline;
}

.letter-spacing {
  letter-spacing: 0.08em;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal {
  font-style: normal;
}

.max-height {
  height: 100dvh;
  max-height: 100dvh;
}

.clip {
  overflow: clip;
}

.converter-logo {
  margin-bottom: 7rem;
}

.join-round > *:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}
.join-round > *:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.join-round-vertical > *:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}
.join-round-vertical > *:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
.spinner {
  animation: 1s spin linear infinite;
  overflow: visible;
}

.stretch-self {
  align-self: stretch;
}

.center-self {
  align-self: center;
}

.flip-horizontal {
  transform: scaleX(-1);
}

.flip-vertical {
  transform: scaleY(-1);
}

.required::before {
  content: "*";
  color: var(--true-blue);
  display: inline-block;
  width: 1em;
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.loading {
  background: var(--dark-32);
  @media (prefers-reduced-motion: no-preference) {
    background: linear-gradient(to right, var(--dark-32) 30%, var(--dark-16) 50%, var(--dark-32) 70%);
    animation: loading 1.5s infinite linear;
    background-size: 200% 100%;
  }
}

@keyframes loading {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: -200% 0%;
  }
}

.overflow {
  overflow: hidden;
}
