$colors: (
  "true-blue": "hsl(216, 100%, 51%)",
  "true-blue--disabled": "hsl(216, 50%, 51%)",
  "true-blue--hover": "hsl(216, 100%, 56%)",
  "true-blue--active": "hsl(216, 100%, 56%)",
  "light-blue-100": "hsl(205, 98%, 55%)",
  "light-blue-64": "hsl(205, 99%, 71%)",
  "light-blue-32": "hsl(205, 97%, 85%)",
  "light-blue-16": "hsl(206, 100%, 93%)",
  "light-blue-8": "hsl(207, 100%, 96%)",
  "white": "hsl(0, 0%, 100%)",
  "dark-100": "hsl(210, 33%, 5%)",
  "dark-100--disabled": "hsl(210, 10%, 30%)",
  "dark-100--hover": "hsl(210, 20%, 15%)",
  "dark-100--active": "hsl(210, 20%, 15%)",
  "dark-72": "hsl(210, 4%, 31%)",
  "dark-64": "hsl(220, 2%, 40%)",
  "dark-32": "hsl(220, 2%, 70%)",
  "dark-24": "hsl(210, 2%, 77%)",
  "dark-16": "hsl(210, 3%, 85%)",
  "dark-8": "hsl(180, 3%, 92%)",
  "dark-4": "hsl(0, 0%, 96%)",
  "dark-2": "hsl(0, 0%, 98%)",
  "min-grey": "hsl(215, 13%, 46%)",
  "min-gray": "var(--min-grey)",
  "green": "hsl(158, 100%, 36%)",
  "green--disabled": "hsl(158, 30%, 42%)",
  "green--hover": "hsl(158, 100%, 39%)",
  "green--active": "hsl(158, 100%, 39%)",
  "background": "#FFFFFF",
  "red": "hsl(0, 68%, 48%)",
  "red--disabled": "hsl(0, 40%, 48%)",
  "red--hover": "hsl(0, 75%, 52%)",
  "red--active": "hsl(0, 75%, 52%)",
);

:root {
  --blue-gradient-start: #48bdff;
  --blue-gradient-stop: #2260fe;
  --blue-gradient: linear-gradient(
    var(--blue-gradient-angle),
    var(--blue-gradient-start) 0%,
    var(--blue-gradient-stop) 100%
  );
  --blue-gradient-angle: 139deg;

  @each $clr, $val in $colors {
    --#{$clr}: #{$val};
  }
}

@each $clr, $val in $colors {
  .fg-#{$clr} {
    color: var(--#{$clr});
  }
  .bg-#{$clr} {
    background-color: var(--#{$clr});
  }
  .fill-#{$clr} {
    fill: var(--#{$clr});
  }
}
