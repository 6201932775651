:root {
  --true-blue: #076aff;
  --true-blue-64: rgba(7, 106, 255, 0.64);
  --light-blue-100: rgba(25, 157, 253, 1);
  --light-blue-64: rgba(25, 157, 253, 0.64);
  --light-blue-32: rgba(25, 157, 253, 0.32);
  --light-blue-16: rgba(25, 157, 253, 0.16);
  --light-blue-8: rgba(25, 157, 253, 0.08);
  --white: #ffffff;
  --dark-100: rgba(8, 12, 16, 1);
  --dark-72: rgba(8, 12, 16, 0.72);
  --dark-32: rgba(8, 12, 16, 0.32);
  --dark-24: rgba(8, 12, 16, 0.24);
  --dark-16: rgba(8, 12, 16, 0.16);
  --dark-8: rgba(8, 12, 16, 0.08);
  --dark-4: rgba(8, 12, 16, 0.04);
  --dark-2: rgba(8, 12, 16, 0.02);
  --min-grey: rgba(102, 115, 133, 1);
  --min-gray: var(--min-grey);
  --green: rgba(00, 186, 119, 1);
  --background: rgba(237, 239, 241);
  --blue-gradient-angle: 139deg;
  --blue-gradient-start: #48bdff;
  --blue-gradient-stop: #2260fe;
  --blue-gradient: linear-gradient(
    var(--blue-gradient-angle),
    var(--blue-gradient-start) 0%,
    var(--blue-gradient-stop) 100%
  );
  --red: #cf2828;
}
.fg-true-blue {
  color: var(--true-blue);
}
.bg-true-blue {
  background-color: var(--true-blue);
}
.fg-light-blue-100 {
  color: var(--light-blue-100);
}
.bg-light-blue-100 {
  background-color: var(--light-blue-100);
}
.fg-light-blue-64 {
  color: var(--light-blue-64);
}
.bg-light-blue-64 {
  background-color: var(--light-blue-64);
}
.fg-light-blue-32 {
  color: var(--light-blue-32);
}
.bg-light-blue-32 {
  background-color: var(--light-blue-32);
}
.fg-light-blue-16 {
  color: var(--light-blue-16);
}
.bg-light-blue-16 {
  background-color: var(--light-blue-16);
}
.fg-light-blue-8 {
  color: var(--light-blue-8);
}
.bg-light-blue-8 {
  background-color: var(--light-blue-8);
}
.fg-white {
  color: var(--white);
}
.bg-white {
  background-color: var(--white);
}
.fg-dark-100 {
  color: var(--dark-100);
}
.bg-dark-100 {
  background-color: var(--dark-100);
}
.fg-dark-72 {
  color: var(--dark-72);
}
.bg-dark-72 {
  background-color: var(--dark-72);
}
.fg-dark-32 {
  color: var(--dark-32);
}
.bg-dark-32 {
  background-color: var(--dark-32);
}
.fg-dark-24 {
  color: var(--dark-24);
}
.bg-dark-24 {
  background-color: var(--dark-24);
}
.fg-dark-16 {
  color: var(--dark-16);
}
.bg-dark-16 {
  background-color: var(--dark-16);
}
.fg-dark-8 {
  color: var(--dark-8);
}
.bg-dark-8 {
  background-color: var(--dark-8);
}
.fg-dark-4 {
  color: var(--dark-4);
}
.bg-dark-4 {
  background-color: var(--dark-4);
}
.fg-dark-2 {
  color: var(--dark-2);
}
.bg-dark-2 {
  background-color: var(--dark-2);
}
.fg-min-grey {
  color: var(--min-grey);
}
.bg-min-grey {
  background-color: var(--min-grey);
}
.fg-min-gray {
  color: var(--min-gray);
}
.bg-min-gray {
  background-color: var(--min-gray);
}
.fg-green {
  color: var(--green);
}
.bg-green {
  background-color: var(--green);
}
.fg-background {
  color: var(--background);
}
.bg-lightGray {
  background-color: var(--background);
}
.bg-gradient {
  background: var(--blue-gradient);
}
.fill-gradient {
  fill: url("#blue_gradient") var(--light-blue-64);
}
.fg-gradient {
  background: var(--blue-gradient);
  background-clip: text;
  color: transparent;
}
.fg-red {
  color: var(--red);
}
.bg-red {
  background-color: var(--red);
}
.fg-transparent {
  color: transparent;
}
.bg-transparent {
  background-color: transparent;
}
