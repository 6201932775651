.checkbox {
  position: relative;
  appearance: none;
  height: 1em;
  width: 1em;
  border: 1px solid var(--dark-16);
  color: var(--white);
  background-color: white;
  box-shadow: 0 0 4px 2px var(--dark-8);
  aspect-ratio: 1 / 1;
}

.checkbox[data-shadow="false"] {
  box-shadow: none;
}

.checkbox:checked {
  background-color: var(--true-blue);
}

.checkbox::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "✔";
  font-size: 0.6em;
  visibility: hidden;
}

.checkbox:checked::after {
  visibility: visible;
}

.checkbox:disabled {
  border: 1px solid var(--dark-8);
  background-color: var(--dark-4);
  box-shadow: none;
}
.checkbox:disabled:checked {
  background-color: var(--dark-16);
}
