.details {
  padding: 1em;
  background: #ffffff;
}

.details summary {
  cursor: pointer;
  list-style: none;
  color: var(--min-grey);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2em;
}
.details[open] summary {
  color: var(--dark-100);
}
.details :is(.details__icon, ._icon) {
  min-width: 1em;
}
.details[open] :is(.details__icon, ._icon) {
  rotate: 180deg;
}
.details summary::-webkit-details-marker,
.details summary::marker {
  content: "";
  display: none;
}
