.text-column {
  width: min(100%, 50em);
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.half-flow {
  --flow-gap: 1rem;
}

.flow > * + *:not(.disable-flow) {
  margin-top: var(--flow-gap, 2rem);
}
.flow-row > * + *:not(.disable-flow) {
  margin-left: var(--flow-row-gap, 1em);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-start {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.split {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

.column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.three-split {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
}
.three-split > *:first-child {
  justify-self: start;
}
.three-split > *:last-child {
  justify-self: end;
}

.quadrants {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.z-index-1 {
  z-index: 1;
}
