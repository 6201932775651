.input {
  display: inline-block;
  border-radius: 12px;
  font-size: 16px;
  line-height: 1.2em;

  padding-inline: 1em;
  padding-block: 0.75em;

  position: relative;

  color: var(--input-fg);
  background-color: var(--input-bg);
  // border-width: 1px;
  // border-style: solid;
  // border-color: var(--input-border);
  outline: none;

  &::placeholder {
    color: var(--input-placeholder);
  }

  &:where(textarea) {
    resize: vertical;
    min-height: 175px;
    overflow: hidden;
  }

  &:where(
      [type="text"],
      [type="url"],
      [type="password"],
      [type="tel"],
      [type="search"],
      [type="number"],
      [type="email"],
      [type="date"],
      [type="datetime-local"],
      [type="month"],
      [type="time"],
      [type="week"],
      :not([type])
    ) {
    &:where(:focus, :focus-within) {
      color: var(--input-fg--focus, var(--white));
      background-color: var(--input-bg--focus);
    }

    &:where(:focus-visible) {
      box-shadow: 0 0 0 0.15em var(--input-shadow--focus);
    }

    &:where(:hover) {
      color: var(--input-fg--hover, var(--white));
      background-color: var(--input-bg--hover);
      border-color: var(--input-border--hover);
    }

    &:where(:focus, :focus-within) {
      border-color: var(--input-border--focus);
    }

    &:where(:active) {
      color: var(--input-fg--active, var(--white));
      background-color: var(--input-bg--active);
      border-color: var(--input-border--active);
      box-shadow: none;
    }

    &:where(:invalid) {
      border-color: var(--input-border--invalid);
    }

    &:where(
        [data-show-valid]:valid:required [data-show-valid]:valid[max],
        [data-show-valid]:valid[min],
        [data-show-valid]:valid[maxlength],
        [data-show-valid]:valid[minlength],
        [data-show-valid]:valid[pattern]
      ) {
      border-color: var(--input-border--valid);
    }

    &:where(:read-only) {
      cursor: not-allowed;
    }

    &:where(:disabled, [aria-disabled="true"]) {
      cursor: not-allowed;
      color: var(--input-fg--disabled, var(--white));
      background-color: var(--input-bg--disabled);
      border-color: var(--input-border--disabled);
    }
  }

  &:where(:not([data-variant])) {
    --input-fg: var(--min-grey);
    --input-placeholder: var(--dark-32);
    --input-bg: var(--white);
    --input-border: var(--dark-8);

    --input-fg--disabled: var(--dark-32);
    --input-bg--disabled: var(--dark-8);
    --input-border--disabled: transparent;

    --input-fg--focus: var(--min-grey);
    --input-bg--focus: var(--white);
    --input-border--focus: var(--dark-32);
    --input-shadow--focus: var(--light-blue-32);

    --input-fg--active: var(--min-grey);
    --input-bg--active: var(--white);
    --input-border--active: var(--dark-32);

    --input-fg--hover: var(--min-grey);
    --input-bg--hover: var(--white);
    --input-border--hover: var(--dark-16);

    --input-border--invalid: var(--red);
    --input-border--valid: var(--green);

    --input-border--invalid: var(--red);
    --input-border--valid: var(--green);
  }
}

.button__border__unclicked {
  border-color: var(--input-border--focus) !important;
}
